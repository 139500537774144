<template>
  <div class="media-video">
    <div class="media-video__wrap">
      <iframe class="media-video__item"
              :src="url" :title="title"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
    </div>
  </div>
</template>
<script>
    export default {
        name: 'AppMediaVideo',
        props: {
            src: {
                type: String,
                required: true
            },
            title: {
                type: String,
                default: ''
            }
        },
		data() {
			return {
				baseUrl: process.env.VUE_APP_BASE_URL_DOMAIN
			}
		},
		computed: {
			url() {
				return this.baseUrl + this.src;
			}
		}
    }
</script>
<style lang="scss">
  .media-video {
    width: 1200px;
    max-width: 100%;
    background-color: #000;

    &__wrap {
      position: relative;
      max-width: 1200px;
      width: 100%;
      height: 700px;
    }


    &__item {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 768px) {
    .media-video__wrap {
      height: 400px!important;
    }
  }

  @media (max-width: 500px) {
    .media-video__wrap {
      height: 250px!important;
    }
  }

</style>
