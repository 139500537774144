<template>
	<main class="page-container container">
		<div class="section__top" :class="isMobileSmall ? 'mb-10' : 'mb-30'">
			<div class="section__top-left">
				<AppText
					:size="isMobileSmall ? 18 : isMobile ? 22 : 26"
					weight="600"
					:line-height="isMobileSmall ? 22 : isMobile ? 28 : 34"
					>{{ $t("instructions") }}
				</AppText>
			</div>
		</div>

		<div class="content__holder">
			<div class="content__main">
				<div class="video-player">
					<iframe
						:src="mainVideo.videoUrl"
						height="510px"
						width="100%"
						frameborder="0"
						allowfullscreen
					></iframe>
				</div>

				<AppText size="18" weight="600" line-height="26" class="mb-30 mt-10">
					{{ mainVideo.mavzu[`${$store.state.clng}`] }}
				</AppText>

				<div class="videos-grid">
					<div v-for="video in data" @click="setVideoToMain(video)">
						<div>
							<iframe :src="video.videoUrl" allowfullscreen frameborder="0"></iframe>
						</div>
						<div class="videos-grid-title">
							{{ video.mavzu[`${$store.state.clng}`] }}
						</div>
					</div>
				</div>
			</div>
			<div class="sidebar__main">
				<AppText weight="700" size="16" line-height="21" class="sidebar__main-title mb-25"
					>{{ $t("fileInstruction") }}:
				</AppText>
				<ul class="sidebar__instruction-ul" v-if="$store.state.clng">
					<li class="sidebar__instruction-list" v-for="(doc, idx) in documents" :key="idx">
						<div class="d-flex file-container">
							<template v-if="!doc.docType">
								<a
									:href="`${doc.path + $store.state.clng}.pdf`"
									:download="$t(doc.word)"
									class="sidebar__instruction-link"
								>
									<img
										src="../../assets/icons/pdf.svg"
										class="mr-8"
										width="20"
										height="20"
										alt="img"
									/>
									<AppText size="14" line-height="18">
										{{ $t(doc.word) }}
									</AppText>
								</a>
							</template>
							<template v-else>
								<a
									:href="`${doc.path + $store.state.clng}${doc.docType}`"
									:download="$t(doc.word)"
									class="sidebar__instruction-link"
								>
									<img
										v-if="doc.imgUrl !== 'word'"
										src="../../assets/icons/pdf.svg"
										class="mr-8"
										width="20"
										height="20"
										alt="img"
									/>
									<img
										v-else
										src="../../assets/icons/word.png"
										class="mr-8"
										width="20"
										height="20"
										alt="img"
									/>
									<AppText size="14" line-height="18">
										{{ $t(doc.word) }}
									</AppText>
								</a>
							</template>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</main>
</template>

<script>
import AppText from "../../components/shared-components/AppText";
import BlockWrap from "../../components/shared-components/BlockWrap";
import AppCard from "../../components/shared-components/AppCard";
import AppModal from "../../components/shared-components/AppModal";
import AppMediaVideo from "../../components/shared-components/AppMediaVideo";
import AppSlider from "../../components/shared-components/AppSlider";

export default {
	name: "index",
	components: {
		AppSlider,
		AppMediaVideo,
		AppModal,
		AppCard,
		BlockWrap,
		AppText,
	},
	data() {
		return {
			query: {
				skip: 0,
				take: 10,
			},
			data: [
				{
					videoUrl: "https://www.youtube.com/embed/qvGrXyRPwZE",
					mavzu: {
						uz: "TIF TN kodlari qo‘shish hamda oferta joylashtirish yo‘riqnomasi",
						ru: "ТИФ ТН кодлари қўшиш ҳамда оферта жойлаштириш йўриқномаси",
						cyrl: "ТИФ ТН кодлари қўшиш ҳамда оферта жойлаштириш йўриқномаси",
					},
				},
				{
					videoUrl: "https://www.youtube.com/embed/Vgl7IGCWA2Q",
					mavzu: {
						uz: "Yetkazib beruvchining ro‘yxatdan o‘tish yo‘riqnomasi",
						ru: "Етказиб берувчининг рўйхатдан ўтиш йўриқномаси",
						cyrl: "Етказиб берувчининг рўйхатдан ўтиш йўриқномаси",
					},
				},
				{
					videoUrl: "https://www.youtube.com/embed/2ZRah-dvstQ",
					mavzu: {
						uz: "Buyurtmachining ro‘yxatdan o‘tish yo‘riqnomasi",
						ru: "Буюртмачининг рўйхатдан ўтиш йўриқномаси",
						cyrl: "Буюртмачининг рўйхатдан ўтиш йўриқномаси",
					},
				},
				{
					videoUrl: "https://www.youtube.com/embed/2CWXZd3iN1g",
					mavzu: {
						uz: "Sertifikat va dilerlik qo‘shish yo‘riqnomasi",
						ru: "Сертификат ва дилерлик қўшиш йўриқномаси",
						cyrl: "Сертификат ва дилерлик қўшиш йўриқномаси",
					},
				},
			],
			modalVisible: false,
			saveLink: "",
			mainVideo: {
				videoUrl: "https://www.youtube.com/embed/qvGrXyRPwZE",
				mavzu: {
					uz: "TIF TN kodlari qo‘shish hamda oferta joylashtirish yo‘riqnomasi",
					ru: "ТИФ ТН кодлари қўшиш ҳамда оферта жойлаштириш йўриқномаси",
					cyrl: "ТИФ ТН кодлари қўшиш ҳамда оферта жойлаштириш йўриқномаси",
				},
			},
			adminUrl: process.env.VUE_APP_BASE_URL_DOMAIN,
			documents: [
				{
					path: "/docs/inst/ro",
					word: "instructionForUsers",
				},
				{
					path: "/docs/inst/yo",
					word: "instructionForProducts",
				},
				{
					path: "/docs/inst/cert",
					word: "instructionForCert",
				},
				{
					path: "/docs/inst/diler",
					word: "instructionForDiler",
				},
				{
					path: "/docs/inst/savdo",
					word: "instructionForCellWithoutPrePayment",
				},
				{
					path: "/docs/inst/tolov",
					word: "instructionForRejectingOrEnablingCell",
				},
				{
					path: "/docs/inst/budget",
					word: "instructionsCustomerBudget",
					docType: ".docx",
					imgUrl: "word",
				},
				{
					path: "/docs/inst/budget_all",
					word: "instructionsCustomerBudgetAll",
					docType: ".docx",
					imgUrl: "word",
				},
				{
					path: "/docs/inst/service",
					word: "instructionService",
				},
				{
					path: "/docs/inst/hokimiyat",
					word: "instructionGovernment",
				},
			],
		};
	},
	methods: {
		setVideoToMain(video) {
			this.mainVideo = video;
		},
		openModal(saveLink) {
			this.modalVisible = true;
			this.saveLink = saveLink;
		},

		adjustVideoUrls(data) {
			const newData = data.map((item) => {
				const splittedVideo = item.videoUrl ? item.videoUrl.split(";") : null;
				const newUrl = item.videoUrl ? `${this.adminUrl}${splittedVideo[splittedVideo.length - 1]}` : null;

				return {
					...item,
					videoUrl: newUrl,
					files: this.cutNull(item.files),
				};
			});

			this.mainVideo = newData[0].videoUrl
				? {
						title: newData[0].mavzu[`${this.$store.state.clng}`],
						videoUrl: newData[0].videoUrl,
				  }
				: {};
			return newData;
		},

		showMainVideo(item) {
			console.log("sdsd", item);
			this.mainVideo = {
				title: item.mavzu[`${this.$store.state.clng}`],
				videoUrl: item.videoUrl,
			};
		},

		cutNull(url) {
			if (url) {
				let arrayUrl = url.split(";");
				arrayUrl = arrayUrl.filter((item) => item !== "null");

				return this.adminUrl + arrayUrl;
			} else {
				return url;
			}
		},
		getFileName(fileName) {
			if (fileName != null) {
				return fileName.slice(19);
			} else {
				return fileName;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.videos-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: 35px;
	margin-top: 50px;
	.videos-grid-title {
		text-align: center;
		font-size: 0.9rem;
	}
	& > div {
		overflow: hidden;
		border-radius: 10px;
		cursor: pointer;
		iframe {
			border-radius: 10px;
			aspect-ratio: 18/9;
			pointer-events: none;
			height: 100%;
		}
	}
}
@media (max-width: 768px) {
	.sidebar__main {
		width: 100% !important;
		min-width: none !important;
		max-width: none !important;
	}
}
@media (max-width: 1100px) {
	.sidebar__main {
		margin-bottom: 50px;
	}
}
.sidebar__instruction {
	&-ul {
	}

	&-list {
		margin-bottom: 18px;

		&:last-child {
			margin-bottom: 0;
		}

		&:hover {
			text-decoration: underline;
			color: #38b1f5;
		}
	}

	&-link {
		display: flex;
		align-items: center;
	}
}

.video-player {
	height: 510px;
	background-color: #ccc;
	border-radius: 8px;
	overflow: hidden;
}

.slider__card {
	cursor: pointer;
	width: 100%;
	&-video {
		width: 100%;
		min-height: 150px;
		height: 100%;
		position: relative;
		border-radius: 8px;
		overflow: hidden;

		video {
			max-height: 150px;
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 8px;
			overflow: hidden;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&-content {
	}
}

.file-container {
	align-items: center;
}

.file-size {
	white-space: nowrap;
}

.content {
	&__holder {
		grid-column-gap: 40px;
	}
}
</style>
